define("ember-promise-modals/templates/components/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rfbY8BJ7",
    "block": "{\"symbols\":[\"@modal\"],\"statements\":[[10,\"div\"],[15,1,[32,0,[\"modalElementId\"]]],[15,0,[31,[\"epm-modal \",[32,0,[\"optionsClassName\"]],\" \",[32,0,[\"animatingOutClass\"]]]]],[12],[2,\"\\n  \"],[1,[30,[36,1],[[32,1,[\"_name\"]]],[[\"data\",\"close\"],[[32,1,[\"_data\"]],[30,[36,0],[[32,0],\"close\"],null]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"component\"]}",
    "moduleName": "ember-promise-modals/templates/components/modal.hbs"
  });

  _exports.default = _default;
});