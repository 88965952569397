define("ember-promise-modals/modal", ["exports", "@ember/test-waiters"], function (_exports, _testWaiters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let Modal = (_dec = Ember.computed('_deferredOutAnimation'), (_class = class Modal {
    constructor(service, name, data) {
      let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      this._service = service;
      this._name = name;
      this._data = data;
      this._options = options;
      this._result = undefined;
      this._deferred = Ember.RSVP.defer();
      this._deferredOutAnimation = undefined;
      this._componentInstance = undefined;
    }

    get result() {
      return this._result;
    }

    get isClosing() {
      return Boolean(this._deferredOutAnimation);
    }

    close(result) {
      if (this._componentInstance) {
        this._componentInstance.closeModal(result);
      }
    }

    then(onFulfilled, onRejected) {
      return this._deferred.promise.then(onFulfilled, onRejected);
    }

    _resolve(result) {
      if (!this._deferredOutAnimation) {
        Ember.set(this, '_deferredOutAnimation', Ember.RSVP.defer());

        if (this._options.onAnimationModalOutEnd) {
          this._deferredOutAnimation.promise.then(() => this._options.onAnimationModalOutEnd()).catch(() => {});
        }

        this._result = result;

        this._deferred.resolve(result);

        (0, _testWaiters.waitForPromise)(this._deferredOutAnimation.promise);
      }
    }

    _remove() {
      this._service._stack.removeObject(this);

      if (this._service._stack.length === 0) {
        this._service._onLastModalRemoved();
      }

      this._componentInstance = undefined;

      if (this._deferredOutAnimation) {
        this._deferredOutAnimation.resolve();
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "isClosing", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isClosing"), _class.prototype)), _class));
  _exports.default = Modal;
});