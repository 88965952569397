define("ember-body-class/instance-initializers/body-class", ["exports", "ember-body-class/util/bodyClass", "ember-get-config"], function (_exports, _bodyClass, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    // Default to true when not set
    let _includeRouteName = true;

    if (_emberGetConfig.default['ember-body-class'] && _emberGetConfig.default['ember-body-class'].includeRouteName === false) {
      _includeRouteName = false;
    }

    Ember.Route.reopen({
      classNames: [],
      bodyClasses: null,

      init() {
        this._super(...arguments);

        Ember.set(this, 'bodyClasses', []);
      },

      _getRouteDepthClasses() {
        let routeParts = this.get('routeName').split('.');
        let routeDepthClasses = routeParts.slice(0);
        let currentSelector = [];
        routeParts.forEach(part => {
          currentSelector.push(part);
          routeDepthClasses.push(currentSelector.join(`-`));
        });
        return routeDepthClasses;
      },

      addClasses: Ember.on('activate', function () {
        this._setClassNamesOnBodyElement();
      }),

      _setClassNamesOnBodyElement() {
        const {
          body
        } = Ember.getOwner(this).lookup('service:-document');
        ['bodyClasses', 'classNames'].forEach(classes => {
          this.get(classes).forEach(function (klass) {
            (0, _bodyClass.addClass)(body, klass);
          });
        });

        if (_includeRouteName) {
          this._getRouteDepthClasses().forEach(depthClass => {
            (0, _bodyClass.addClass)(body, depthClass);
          });
        }
      },

      updateClasses: Ember.observer('bodyClasses.[]', 'classNames.[]', function () {
        const {
          body
        } = Ember.getOwner(this).lookup('service:-document');
        ['bodyClasses', 'classNames'].forEach(classes => {
          this.get(classes).forEach(function (klass) {
            (0, _bodyClass.removeClass)(body, klass);
          });
        });

        this._setClassNamesOnBodyElement();
      }),
      removeClasses: Ember.on('deactivate', function () {
        // for some reason we're getting deactivate called too early and it's
        // removing the classes in fastboot only.
        if (typeof FastBoot !== 'undefined') {
          return;
        }

        const {
          body
        } = Ember.getOwner(this).lookup('service:-document');
        ['bodyClasses', 'classNames'].forEach(classes => {
          this.get(classes).forEach(function (klass) {
            (0, _bodyClass.removeClass)(body, klass);
          });
        });

        if (_includeRouteName) {
          this._getRouteDepthClasses().forEach(depthClass => {
            (0, _bodyClass.removeClass)(body, depthClass);
          });
        }
      })
    });
  }

  var _default = {
    name: 'body-class',
    initialize: initialize
  };
  _exports.default = _default;
});