define("ember-body-class/mixins/body-class", ["exports", "ember-body-class/util/bodyClass"], function (_exports, _bodyClass) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      loading(transition) {
        const document = Ember.getOwner(this).lookup('service:-document');
        const body = document.body;
        (0, _bodyClass.addClass)(body, 'loading');
        transition.finally(function () {
          (0, _bodyClass.removeClass)(body, 'loading');
        });
        return true;
      },

      error: function
        /* error, transition */
      () {
        const document = Ember.getOwner(this).lookup('service:-document');
        const body = document.body;
        (0, _bodyClass.addClass)(body, 'error');
        let router = this._router;

        if (router) {
          router.on('didTransition', function () {
            (0, _bodyClass.removeClass)(body, 'error');
          });
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});