define("ember-promise-modals/templates/components/modal-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hSUlmV38",
    "block": "{\"symbols\":[\"modal\",\"index\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"modals\",\"_stack\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"epm-backdrop \",[30,[36,0],[[32,1,[\"isClosing\"]],\"epm-out\"],null]]]],[14,\"tabindex\",\"-1\"],[14,\"role\",\"presentation\"],[14,\"aria-hidden\",\"true\"],[15,\"data-test-epm-backdrop\",[31,[[32,2]]]],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"epm-modal-container\"],[12],[2,\"\\n    \"],[8,\"epm-modal\",[[16,\"data-test-epm-modal\",[31,[[32,2]]]]],[[\"@modal\"],[[32,1]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "ember-promise-modals/templates/components/modal-container.hbs"
  });

  _exports.default = _default;
});